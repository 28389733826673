import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../utils/global-styles/theme";
import { navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import Checkbox from "../components/shared/Checkbox/Checkbox";
import PrimaryButton from "../components/shared/PrimaryButton/PrimaryButton";
import { _post, _contentfulPost } from "../utils/services/api.service";
import useReconcileAndMigrateData from "../hooks/reconcile-and-migrate-data";
import { isLoggedIn } from "../utils/services/methods";

const migrate = ({
  location: { state },
  pageContext: { locale },
  data: reconcile,
}) => {
  const data = reconcile?.reconcileData?.nodes[0]?.contentModules[0],
    [userDidConfirm, setUserDidConfirm] = useState(false),
    [accountData, setAccountData] = useState(state ? { ...state.user } : {}),
    determineDestination = response => {
      if (response.message === "Problem migrating account")
        navigate(`/reconcile${locale === "es" ? "/es" : ""}?stage=failure`);
      else
        !!isLoggedIn()
          ? navigate("/")
          : navigate(`/onboardingSurvey/${locale === "es" ? "es" : ""}}`, {
              state: {
                account: {
                  email: accountData.email,
                  first_name: accountData.first_name,
                },
              },
            });
    },
    handleClick = async () => {
      if (userDidConfirm) {
        const contentfulCourses = await _contentfulPost({
          query: `query($courseTitle: String){
              courseDetailPageCollection(where: {title_contains: $courseTitle}) {
                items {
                  title
                  courseId
                }
              }
            }`,
          variables: {
            courseTitle: accountData["course_name"],
          },
        });

        const {
          courseDetailPageCollection: { items } = { items: undefined },
        } = contentfulCourses;
        if (items !== undefined && Array.isArray(items) && items.length > 0) {
          //If we get a response from Contentful with the course_id, reconcile it with the AA API
          const migrateRequest = await _post({
            endpoint: "reconciles",
            args: {
              ...accountData,
              course_id: items[0].courseId,
            },
          });
          determineDestination(migrateRequest.response);
        } else {
          // If we do not have a course_id in Contentful for this user's course, show the user the failure page
          navigate(`/reconcile${locale === "es" ? "/es" : ""}?stage=failure`, {
            state: {
              account: {
                email: accountData.email,
                first_name: accountData.first_name,
              },
            },
          });
        }
      }
    },
    OuterWrapper = styled("div")`
      display: grid;
      grid-template: 120px 1fr 120px / 30% 50% 20%;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template-columns: 10px 1fr 10px;
      }
    `,
    InteriorWrapper = styled("div")`
      grid-area: 2 / 2 / span 1;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-area: 1 / 2 / span 2 / span 1;
      }
    `,
    Title = styled("h1")`
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.04px;
      line-height: 48px;
      margin-bottom: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 32px;
        letter-spacing: -0.83px;
        line-height: 38px;
        margin-top: 50px;
      }
    `,
    SubTitle = styled("h4")`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-bottom: 50px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        line-height: 24px;
      }
    `,
    FinePrint = styled("p")`
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 20px;
      margin-bottom: 50px;
      font-family: ${theme.fontFaces.help};
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 13px;
        letter-spacing: -0.28px;
        line-height: 16px;
      }
    `;

  useEffect(() => {
    if (!state) return navigate("/");
  }, []);

  return (
    <Layout locale={locale}>
      <OuterWrapper>
        <InteriorWrapper>
          <Title>{data.title}</Title>
          <SubTitle>{data.subTitle}</SubTitle>
          <Checkbox
            item={{
              name: "confirm",
              label: data.options[0],
            }}
            checked={userDidConfirm}
            onChange={() => setUserDidConfirm(!userDidConfirm)}
            checkboxBorderColor="primary-checkbox-border-override"
          />
          {userDidConfirm && <FinePrint>{data.finePrint.finePrint}</FinePrint>}
          <PrimaryButton
            text={data.cta[0].linkText}
            handleClick={handleClick}
            styles={css`
              padding: 10px 50px;
              margin-top: ${userDidConfirm ? "0px" : "50px"};
            `}
          />
        </InteriorWrapper>
      </OuterWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    reconcileData: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Reconciliation & Migration Flow" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        node_locale
        contentModules {
          ... on ContentfulInformationSection {
            id
            informationSection {
              informationSection
            }
          }
          ... on ContentfulMigrationAndReconciliation {
            name
            title
            subTitle
            options
            finePrint {
              finePrint
            }
            cta {
              linkText
            }
            errorMessage
            inputBox
          }
        }
      }
    }
  }
`;

migrate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.objectOf(PropTypes.string),
    }),
  }),
};

export default migrate;
