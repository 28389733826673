import { graphql, useStaticQuery } from "gatsby";

const useReconcileAndMigrateData = (locale = "en-US") => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Reconciliation & Migration Flow" } }
      ) {
        nodes {
          node_locale
          contentModules {
            ... on ContentfulInformationSection {
              id
              informationSection {
                informationSection
              }
            }
            ... on ContentfulMigrationAndReconciliation {
              name
              title
              subTitle
              options
              finePrint {
                finePrint
              }
              cta {
                linkText
              }
              errorMessage
              inputBox
            }
          }
        }
      }
    }
  `);
  const nodes = data.allContentfulPageLayout.nodes;
  let nodeToReturn;
  nodes.forEach(node => {
    if (node.node_locale === locale) nodeToReturn = node;
  });

  return nodeToReturn;
};

export default useReconcileAndMigrateData;
